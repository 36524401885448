/**
 * @generated SignedSource<<31210c51d61af9ffaf56581abc13886e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TournamentRegistrationTypeEnum = "OPEN" | "ORGANIZATION" | "SCHOOL" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TournamentRegistrationInformation_tournament$data = {
  readonly entityId: number;
  readonly registration: {
    readonly isOpen: boolean;
    readonly registrationEndDate: string;
    readonly registrationStartDate: string | null | undefined;
    readonly registrationType: TournamentRegistrationTypeEnum;
  } | null | undefined;
  readonly " $fragmentType": "TournamentRegistrationInformation_tournament";
};
export type TournamentRegistrationInformation_tournament$key = {
  readonly " $data"?: TournamentRegistrationInformation_tournament$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationInformation_tournament">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentRegistrationInformation_tournament",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationSetup",
      "kind": "LinkedField",
      "name": "registration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOpen",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationStartDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationEndDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tournament",
  "abstractKey": null
};

(node as any).hash = "a652d3bff81bc1f92d10787c8956cda1";

export default node;
