import Link from 'next/link';
import { FC, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { graphql, useFragment } from 'react-relay';
import { useUserToken } from '@ktech/components';
import { TournamentRegistrationInformation_tournament$key } from './__generated__/TournamentRegistrationInformation_tournament.graphql';
import TournamentRegistrationDialog from '../registration/TournamentRegistrationDialog';

type Props = {
    tournament: TournamentRegistrationInformation_tournament$key;
};

const TournamentRegistrationInformation: FC<Props> = ({ tournament: tournamentRef }) => {
    const userToken = useUserToken();
    const [openRegistration, setOpenRegistration] = useState(false);

    const tournament = useFragment(
        graphql`
            fragment TournamentRegistrationInformation_tournament on Tournament {
                entityId
                registration {
                    isOpen
                    registrationType
                    registrationStartDate
                    registrationEndDate
                }
            }
        `,
        tournamentRef
    );

    const registrationIsClosed = !tournament.registration?.isOpen;
    const registrationType = tournament.registration?.registrationType;

    return (
        <Alert severity="info">
            <AlertTitle>
                <FormattedMessage defaultMessage="Registration Information" id="RRb1Bc" />
            </AlertTitle>
            <Typography mb={1}>
                <FormattedMessage
                    defaultMessage="Registration is open from {startDate} {hasEndDate, select, 1 { to {endDate}} other {}}"
                    id="3CpVFd"
                    values={{
                        startDate: (
                            <FormattedDate
                                value={tournament.registration?.registrationStartDate || undefined}
                            />
                        ),
                        hasEndDate: tournament.registration?.registrationEndDate ? 1 : 0,
                        endDate: (
                            <FormattedDate value={tournament.registration?.registrationEndDate} />
                        ),
                    }}
                />
            </Typography>
            <Typography variant="body2" mb={1}>
                {registrationType === 'SCHOOL' && (
                    <FormattedMessage
                        defaultMessage="Registration is open for selected schools only. Please contact the tournament organizer for more information."
                        id="FyHtA0"
                    />
                )}
                {registrationType === 'ORGANIZATION' && (
                    <FormattedMessage
                        defaultMessage="Registration is open for selected organizations only. Please contact the tournament organizer for more information."
                        id="OH5Jpc"
                    />
                )}
            </Typography>

            {registrationType === 'OPEN' &&
                (userToken ? (
                    <Button
                        color="inherit"
                        size="small"
                        onClick={() => setOpenRegistration(true)}
                        disabled={registrationIsClosed}
                    >
                        <FormattedMessage defaultMessage="Register" id="deEeEI" />
                    </Button>
                ) : (
                    <Button
                        color="inherit"
                        size="small"
                        component={Link}
                        href="/auth/sign-in/"
                        disabled={registrationIsClosed}
                    >
                        <FormattedMessage defaultMessage="Sign in to Register" id="vThmEi" />
                    </Button>
                ))}

            {!!userToken?.entityId && (
                <TournamentRegistrationDialog
                    tournamentEntityId={tournament.entityId}
                    ownerUserEntityId={userToken.entityId}
                    open={openRegistration}
                    onClose={() => setOpenRegistration(false)}
                />
            )}
        </Alert>
    );
};

export default TournamentRegistrationInformation;
