import { FC } from 'react';
import { Box, CardMedia } from '@mui/material';
import { getFilePath } from './helper/fileHelper';

type Props = {
    image?: string | null;
    alternativeImage?: string | null;
    height?: number;
    maxHeight?: number;
    alt: string;
};

const PhotoPreviewCardMedia: FC<Props> = ({
    image,
    alternativeImage,
    alt,
    height = 200,
    maxHeight,
}) => {
    return (
        <CardMedia
            sx={() => ({
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            })}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundImage: image
                        ? `url(${getFilePath(image, { width: 0, height: maxHeight || height })})`
                        : alternativeImage
                          ? `url(${alternativeImage})`
                          : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(3px) brightness(0.8)',
                    opacity: 0.5,
                }}
            />
            {image || alternativeImage ? (
                <Box
                    component="img"
                    src={
                        image
                            ? getFilePath(image, { width: 0, height: maxHeight || height })
                            : alternativeImage
                              ? alternativeImage
                              : ''
                    }
                    alt={alt}
                    position="relative"
                    height={maxHeight ? undefined : height}
                    maxHeight={maxHeight}
                    width="100%"
                    sx={{
                        objectFit: 'contain',
                        filter: !image ? 'brightness(0.6)' : 'none',
                    }}
                />
            ) : (
                <Box
                    width="100%"
                    sx={{ aspectRatio: 16 / 9 }}
                    height={maxHeight ? undefined : height}
                />
            )}
        </CardMedia>
    );
};

export default PhotoPreviewCardMedia;
