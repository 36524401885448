/**
 * @generated SignedSource<<f10b77db0ef7db87bd1c03ab9b430246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentInformation_tournament$data = {
  readonly address: {
    readonly fullAddress: string;
  } | null | undefined;
  readonly cover: string | null | undefined;
  readonly email: string | null | undefined;
  readonly info: string | null | undefined;
  readonly name: string;
  readonly phoneNumber: string | null | undefined;
  readonly registration: {
    readonly isActive: boolean;
  } | null | undefined;
  readonly tournamentBanners: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"AdBannerContainer_tournamentBanners">;
  } | null | undefined>;
  readonly website: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentRegistrationInformation_tournament">;
  readonly " $fragmentType": "TournamentInformation_tournament";
};
export type TournamentInformation_tournament$key = {
  readonly " $data"?: TournamentInformation_tournament$data;
  readonly " $fragmentSpreads": FragmentRefs<"TournamentInformation_tournament">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TournamentInformation_tournament",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TournamentRegistrationInformation_tournament"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentRegistrationSetup",
      "kind": "LinkedField",
      "name": "registration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TournamentBanner",
      "kind": "LinkedField",
      "name": "tournamentBanners",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdBannerContainer_tournamentBanners"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tournament",
  "abstractKey": null
};

(node as any).hash = "20689a58b7c389830942f24c059203c2";

export default node;
