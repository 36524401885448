import {
    FormControl,
    InputLabel,
    Select,
    FormControlProps,
    SelectProps,
    FilledInput,
    FormHelperText,
} from '@mui/material';
import { Field } from 'formik';
import { FC, ReactNode } from 'react';

type Props = {
    label?: ReactNode;
    name: string;
    children: ReactNode;
    helperText?: ReactNode;
    size?: FormControlProps['size'];
    formControlProps?: Omit<FormControlProps, 'size'>;
    selectProps?: Omit<SelectProps, 'name' | 'size'>;
};

const FormikSelect: FC<Props> = ({
    label,
    name,
    size,
    children,
    formControlProps,
    selectProps,
    helperText,
}) => {
    return (
        <FormControl {...formControlProps}>
            {label && (
                <InputLabel
                    required={selectProps?.required}
                    size={size === 'small' ? 'small' : undefined}
                >
                    {label}
                </InputLabel>
            )}
            <Field
                as={Select}
                label={label}
                name={name}
                size={size}
                input={<FilledInput hiddenLabel={!label} />}
                {...selectProps}
                notched={undefined}
            >
                {children}
            </Field>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default FormikSelect;
