import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import Mail from '@mui/icons-material/Mail';
import Phone from '@mui/icons-material/Phone';
import Place from '@mui/icons-material/Place';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';

import AdBannerContainer from '@/components/adBanner/AdBannerContainer';
import PhotoPreviewCardMedia from '@ktech/components/src/PhotoPreviewCardMedia';

import { TournamentInformation_tournament$key } from './__generated__/TournamentInformation_tournament.graphql';
import TournamentRegistrationInformation from './TournamentRegistrationInformation';
import { filterFalsy } from '@ktech/relay';

type Props = {
    tournament: TournamentInformation_tournament$key;
};

const TournamentInformation: FC<Props> = ({ tournament: tournamentRef }) => {
    const tournament = useFragment(
        graphql`
            fragment TournamentInformation_tournament on Tournament {
                ...TournamentRegistrationInformation_tournament
                name
                cover
                registration {
                    isActive
                }
                info
                address {
                    fullAddress
                }
                email
                phoneNumber
                website
                tournamentBanners {
                    ...AdBannerContainer_tournamentBanners
                }
            }
        `,
        tournamentRef
    );

    return (
        <>
            {tournament.cover && (
                <Box mb={4}>
                    <PhotoPreviewCardMedia
                        image={tournament.cover}
                        alt={tournament.name}
                        maxHeight={500}
                    />
                </Box>
            )}

            <AdBannerContainer
                variant="LARGE_LEADERBOARD"
                tournamentBanners={tournament.tournamentBanners.filter(filterFalsy)}
                mb={4}
            />

            <Grid container gap={4}>
                <Grid item xs={12} md={8}>
                    <div dangerouslySetInnerHTML={{ __html: tournament.info || '' }} />
                </Grid>
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'flex',
                        },
                    }}
                />
                <Grid item xs>
                    {tournament.registration?.isActive && (
                        <Box mb={3}>
                            <TournamentRegistrationInformation tournament={tournament} />
                        </Box>
                    )}

                    <Typography variant="h6" mb={2}>
                        <FormattedMessage defaultMessage="Contact Information" id="RYiGdc" />
                    </Typography>

                    <Grid
                        container
                        spacing={2}
                        columns={{
                            xs: 1,
                            sm: 2,
                        }}
                    >
                        <Grid item xs={1}>
                            <Stack direction="row" gap={1} alignItems="center">
                                <Avatar>
                                    <Place />
                                </Avatar>

                                <Typography variant="body2">
                                    {tournament.address?.fullAddress}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1}>
                            <Stack direction="row" gap={1} alignItems="center" overflow="hidden">
                                <Avatar>
                                    <Mail />
                                </Avatar>

                                <Typography
                                    variant="body2"
                                    component={MuiLink}
                                    href={`mailto:${tournament.email}`}
                                    target="_blank"
                                    flexShrink={1}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                >
                                    {tournament.email}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={1}>
                            <Stack direction="row" gap={1} alignItems="center">
                                <Avatar>
                                    <Phone />
                                </Avatar>

                                <Typography
                                    variant="body2"
                                    component={MuiLink}
                                    href={`tel:${tournament.phoneNumber}`}
                                >
                                    {tournament.phoneNumber}
                                </Typography>
                            </Stack>
                        </Grid>
                        {tournament.website && (
                            <Grid item xs={1}>
                                <Stack direction="row" gap={1} alignItems="center">
                                    <Avatar>
                                        <LinkIcon />
                                    </Avatar>

                                    <Typography
                                        variant="body2"
                                        component={MuiLink}
                                        href={tournament.website}
                                        target="_blank"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                    >
                                        {tournament.website}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TournamentInformation;
