import { Box, BoxProps } from '@mui/material';
import { FC, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { sendGAEvent } from '@next/third-parties/google';
import { getFilePath } from '@ktech/components/src/helper/fileHelper';
import { AdBannerContainer_tournamentBanners$key } from './__generated__/AdBannerContainer_tournamentBanners.graphql';

type Props = {
    tournamentBanners: AdBannerContainer_tournamentBanners$key;
    variant: 'LARGE_LEADERBOARD' | 'WIDE_SKYSCRAPER';
} & BoxProps;

const sizeMap = {
    LARGE_LEADERBOARD: { width: 970, height: 90 },
    WIDE_SKYSCRAPER: { width: 160, height: 600 },
};

const AdBannerContainer: FC<Props> = ({
    tournamentBanners: tournamentBannersRef,
    variant,
    ...boxProps
}) => {
    const tournamentBanners = useFragment(
        graphql`
            fragment AdBannerContainer_tournamentBanners on TournamentBanner @relay(plural: true) {
                tournamentEntityId
                type
                banner
                url
            }
        `,
        tournamentBannersRef
    );

    const banner = tournamentBanners.find(banner => banner.type === variant);
    const tournamentEntityId = banner?.tournamentEntityId;

    useEffect(() => {
        if (tournamentEntityId) {
            sendGAEvent('event', 'tournament_ad_banner_view', {
                tournamentEntityId,
            });
        }
    }, [tournamentEntityId]);

    if (!banner) {
        return null;
    }

    return (
        <Box
            component={banner.url ? 'a' : 'div'}
            href={banner.url}
            onClick={() =>
                sendGAEvent('event', 'tournament_ad_banner_click', {
                    tournamentEntityId,
                })
            }
            target="_blank"
            display="flex"
            justifyContent="center"
            {...boxProps}
        >
            <Box
                component="img"
                width={sizeMap[variant].width}
                height={sizeMap[variant].height}
                maxWidth="100%"
                src={getFilePath(banner.banner)}
                sx={{ objectFit: 'contain' }}
                alt=""
            />
        </Box>
    );
};

export default AdBannerContainer;
