import { GetServerSideProps } from 'next';
import { fetchQuery } from 'react-relay';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { getUserTokenFromIncomingMessage } from '@ktech/cookie-jar';
import { createRelayEnvironment } from '@ktech/relay';
import TournamentPublicLayout, {
    TournamentPublicLayoutQuery,
    TournamentPublicLayoutQueryType,
} from '@/components/tournament/public/TournamentPublicLayout';
import TournamentInformation from '@/components/tournament/public/TournamentInformation';

export type ServerSideProps = {
    tournamentEntityId: number;
    categoryEntityId: number | null;
    controllerEntityId: number | null;
    records: RecordMap;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async context => {
    const tournamentEntityId = Number(context.query.tournamentEntityId) || 0;
    const categoryEntityId = Number(context.query.categoryEntityId) || null;
    const controllerEntityId = Number(context.query.controllerEntityId) || null;

    const environment = createRelayEnvironment({
        nodeServiceHost: context.req.headers.host || process.env.NEXT_PUBLIC_NODE_SERVICE_HOST,
        authorization: getUserTokenFromIncomingMessage(context.req),
        searchParams: { locale: context.locale || '' },
    });

    const data = await fetchQuery<TournamentPublicLayoutQueryType>(
        environment,
        TournamentPublicLayoutQuery,
        {
            tournamentEntityId,
            hasTournamentEntityId: !!tournamentEntityId,
        }
    ).toPromise();

    if (!data?.tournament) {
        return {
            notFound: true,
        };
    }

    const records = environment.getStore().getSource().toJSON();

    return {
        props: {
            tournamentEntityId,
            categoryEntityId,
            controllerEntityId,
            records,
        },
    };
};

export default function PageTournamentIndex({ tournamentEntityId }: ServerSideProps) {
    return (
        <TournamentPublicLayout tournamentEntityId={tournamentEntityId} pageKey="information">
            {tournament => tournament && <TournamentInformation tournament={tournament} />}
        </TournamentPublicLayout>
    );
}
