/**
 * @generated SignedSource<<e8929381efeabcf25c808563c63b8123>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BannerTypeEnum = "LARGE_LEADERBOARD" | "WIDE_SKYSCRAPER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdBannerContainer_tournamentBanners$data = ReadonlyArray<{
  readonly banner: string;
  readonly tournamentEntityId: number;
  readonly type: BannerTypeEnum;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "AdBannerContainer_tournamentBanners";
}>;
export type AdBannerContainer_tournamentBanners$key = ReadonlyArray<{
  readonly " $data"?: AdBannerContainer_tournamentBanners$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdBannerContainer_tournamentBanners">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AdBannerContainer_tournamentBanners",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tournamentEntityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "banner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "TournamentBanner",
  "abstractKey": null
};

(node as any).hash = "8f42351390067d72de5aa9f65a3f61fe";

export default node;
