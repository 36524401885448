type Options = {
    width: number;
    height: number;
};

export function getFilePath(fileName: string, options?: Options): string;
export function getFilePath(fileName: null, options?: Options): undefined;
export function getFilePath(fileName: undefined, options?: Options): undefined;
export function getFilePath(
    fileName: string | null | undefined,
    options?: Options
): string | undefined;
export function getFilePath(fileName: string | null | undefined, options?: Options) {
    return fileName
        ? (process.env.NEXT_PUBLIC_IMAGE_HOST || '') +
              `/uploads/images/${options?.width ? options.width : '-'}/${options?.height ? options.height : '-'}/${fileName}`
        : undefined;
}
